export const toggleTheme = (setting) => {
   // console.log("setting :>> ", setting);
   switch (setting) {
      case "OS": {
         localStorage.removeItem("theme");
         return "OS";
      }
      case "theme": {
         if (localStorage.theme === "light") {
            localStorage.setItem("theme", "dark");
            document.documentElement.classList.add("tw-dark");

            return "dark";
         } else {
            localStorage.setItem("theme", "light");
            document.documentElement.classList.remove("tw-dark");

            return "light";
         }
      }
   }
};

export const initializeTheme = () => {
   // On page load or when changing themes, best to add inline in `head` to avoid FOUC
   if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
         window.matchMedia("(prefers-color-scheme: dark)").matches)
   ) {
      document.documentElement.classList.add("tw-dark");
   } else {
      document.documentElement.classList.remove("tw-dark");
   }
};
