import Footer from '@/components/Layout/MyFooter';
import NavbarTwoCustom from '@/components/Layout/NavbarTwoCustom';
import Navbar from '@/components/Layout/HomeNavbar';
import Switch from '@/components/Switch/Switch';
import Aos from 'aos';
import { useEffect } from 'react';

export default function Layout({ pageProps, children }) {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Switch />
      <Navbar pageProps={pageProps}/>
      <div className="tw-relative ">
        <div className="dark:tw-bg-_black-900">{children}</div>
      </div>
      {/* <div className="tw-relative">
        <Letter />
      </div> */}
      <Footer />
    </>
  );
}
