import empty from "@/images/empty.png";
import baseApiUrl from "@/utils/baseApiUrl";
import baseLocalApiUrlNextjs from "@/utils/baseLocalApiUrlNextjs";
import axios from "axios";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import gbFlag from "../../public/images/gb.svg";
import vnFlag from "../../public/images/vn.svg";

const NavbarTwoCustom = ({ pageProps }) => {
  const router = useRouter();
  const { locale } = router;
  const otherLocale = locale === "vi" ? "en" : "vi";
  const [logo, setLogo] = useState();
  const { otherLangUrl } = pageProps;
  const [navBar, setNavBar] = useState([]);
  const [currentPath, setCurrentPath] = useState("");
  const [menu, setMenu] = useState(true);
  const [activeDropdown, setActiveDropdown] = useState(null);

  useEffect(() => {
    const handleRouteChange = () => {
      setActiveDropdown(null); // Reset active dropdown on route change
    };

    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    const getLogo = async () => {
      const response = await axios.get(
        `${baseApiUrl}/api/site-logo?populate=*`
      );
      setLogo(response.data);
    };
    getLogo();
  }, []);

  useEffect(() => {
    const getNavBar = async () => {
      const response = await axios.get(
        `${baseApiUrl}/api/navbar?populate[navbar][populate]=*&locale=${locale}`
      );
      setNavBar(response.data.data.attributes.navbar);
    };
    getNavBar();
  }, [locale]);

  useEffect(() => {
    setCurrentPath(router.pathname);
    console.log("Current path:", router.pathname);
  }, [router]);

  useEffect(() => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
  }, []);

  const toggleNavbar = () => {
    setMenu(!menu);
  };

  const toggleDropdown = (event, index) => {
    event.preventDefault();
    event.stopPropagation();
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const options = [
    {
      value: "Tiếng Việt - VI",
      code: "vi",
      label: vnFlag,
    },
    {
      value: "English - EN",
      code: "en",
      label: gbFlag,
    },
  ];

  const isActiveLink = (itemUrl) => {
    const currentPath = router.asPath;

    // Special case for home page
    if (itemUrl === "/" && currentPath === "/") {
      return true;
    }

    // Remove trailing slashes and convert to lowercase for both paths
    const normalizedCurrentPath = currentPath.replace(/\/$/, "").toLowerCase();
    const normalizedItemUrl = itemUrl.replace(/\/$/, "").toLowerCase();

    // Check if the current path starts with the item URL
    return (
      normalizedCurrentPath.startsWith(normalizedItemUrl) &&
      normalizedItemUrl !== ""
    );
  };
  useEffect(() => {
    setCurrentPath(router.asPath);
    console.log("Current path (asPath):", router.asPath);
    console.log("Current pathname:", router.pathname);
  }, [router]);

  const activeOption = options.find((item) => item.code === router.locale);

  const classOne = menu
    ? "tw-hidden tw-w-full md:tw-block md:tw-w-auto"
    : "tw-w-full md:tw-block md:tw-w-auto";
  const classTwo = menu
    ? "tw-inline-flex tw-items-center tw-p-2 tw-w-10 tw-h-10 tw-justify-center tw-text-sm tw-text-gray-500 tw-rounded-lg md:tw-hidden hover:tw-bg-gray-100 focus:tw-outline-none focus:tw-ring-2 "
    : "tw-inline-flex tw-items-center tw-p-2 tw-w-10 tw-h-10 tw-justify-center tw-text-sm tw-text-gray-500 tw-rounded-lg md:tw-hidden hover:tw-bg-gray-100 focus:tw-outline-none focus:tw-ring-2 ";

  return (
    <header className="main-header-area">
      <div id="navbar" className="navbar-area tw-z-20">
        <nav className="tw-bg-white tw-border-gray-200 tw-z-20">
          <div className="tw-max-w-screen-xl tw-flex tw-flex-wrap md:tw-flex-nowrap md:tw-gap-8 tw-items-center tw-justify-between tw-mx-auto">
            {logo && (
              <div className="tw-flex tw-items-center tw-space-x-3 rtl:tw-space-x-reverse">
                <Link href="/">
                  <Image
                    src={
                      logo?.data?.attributes?.blackLogo?.data?.attributes?.url
                        ? `${baseLocalApiUrlNextjs}${logo.data.attributes.blackLogo.data.attributes.url}`
                        : empty
                    }
                    alt={
                      logo.data.attributes.blackLogo.data.attributes
                        .alternativeText || "logo-brand-primary"
                    }
                    width={163}
                    height={35}
                    priority
                    className="tw-min-w[163px] tw-min-h-[35px]"
                  />
                </Link>
              </div>
            )}

            <button
              onClick={toggleNavbar}
              className={classTwo}
              type="button"
              aria-controls="navbar-default"
              aria-expanded="false"
            >
              <span className="tw-sr-only">Open main menu</span>
              <svg
                className="tw-w-5 tw-h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>

            <div className={classOne} id="navbar-default">
              <ul className="tw-font-medium tw-flex tw-flex-col md:tw-p-0 tw-border tw-border-gray-100 tw-rounded-lg tw-bg-gray-50 md:tw-flex-row md:tw-space-x-8 rtl:tw-space-x-reverse md:tw-mt-0 md:tw-border-0 md:tw-bg-white">
                {/* {navBar.map((item, index) => {
             
                  return (
                    <li key={index} className="nav-item">
                      <Link
                        href={item.url}
                        className={`nav-link tw-font-[700] tw-py-[10px] md:tw-py-0 lg:tw-text-navigation-lg tw-mt-1 active  ${
                          isActiveLink(item.url)
                            ? "!tw-text-_red-700"
                            : "!tw-text-black-900 hover:!tw-text-_red-700"
                        }`}
                      >
                        {item.name}
                      </Link>
                    </li>
                  );
                })} */}
                {navBar.map((item, index) => (
                  <li key={index} className="nav-item tw-relative tw-group">
                    <div className="tw-relative">
                      <Link
                        href={item.url}
                        className={`nav-link group-hover:tw-text-_red-700  tw-font-bold  max-[500px]:!tw-py-4 md:!tw-py-6 lg:tw-text-lg tw-flex tw-items-center tw-rounded-md tw-transition-colors tw-duration-200 dark:tw-text-black-900 tw-whitespace-nowrap ${
                          isActiveLink(item.url)
                            ? "tw-text-_red-700"
                            : "tw-text-black hover:tw-text-_red-700"
                        }`}
                      >
                        <span>{item.name}</span>
                        {item.subUrl && item.subUrl.length > 0 && (
                          <svg
                            className="tw-w-4 tw-h-4 tw-ml-1"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </Link>
                    </div>
                    {item.subUrl && item.subUrl.length > 0 && (
                      <ul className="tw-absolute  tw-left-0 tw-min-w-[180px] tw-max-w-[300px] tw-z-10 tw-bg-white tw-rounded-md tw-shadow-lg tw-transition-all tw-duration-300 tw-ease-in-out tw-transform tw-scale-95 tw-opacity-0 tw-invisible group-hover:tw-scale-100 group-hover:tw-opacity-100 group-hover:tw-visible">
                        {item.subUrl.map((subItem, subIndex) => (
                          <li key={subIndex}>
                            <Link
                              href={subItem.url}
                              className={`tw-block tw-text-_black-700  tw-px-4 tw-py-4 tw-text-sm tw-font-semibold tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis hover:tw-text-white hover:tw-bg-_red-700 tw-transition-colors tw-duration-200
                          ${subIndex === 0 ? "tw-rounded-t-md" : ""}
                          ${subIndex === item.subUrl.length - 1 ? "tw-rounded-b-md" : ""}
                          ${isActiveLink(subItem.url) ? "tw-text-red-700" : "tw-text-black"}
                        `}
                              title={subItem.name}
                            >
                              {subItem.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}

                <li className="md:tw-ml-4 tw-my-2 md:tw-py-4 max-[500px]:tw-flex max-[500px]:tw-items-center max-[500px]:tw-justify-center ">
                  <Link
                    href={
                      otherLangUrl && otherLocale in otherLangUrl
                        ? otherLangUrl[otherLocale]
                        : router.asPath
                    }
                    locale={otherLocale}
                    scroll={false}
                  >
                    <Image
                      src={activeOption.label}
                      alt={activeOption.value}
                      width={25}
                      height={20}
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default NavbarTwoCustom;
