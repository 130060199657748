import { toggleTheme } from "@/utils/theme";
import { useEffect, useState } from "react";
import MyIcon from "../MyIcon/MyIcon";

export default function Switch() {
  const [isDark, setIsDark] = useState("dark");
  const [active, setActive] = useState(false);

  useEffect(() => {
    setIsDark(window.localStorage?.theme === "dark" ? true : false);
  }, []);

  return (
    <label className="tw-inline-flex tw-cursor-pointer tw-items-center tw-fixed tw-right-0 tw-top-[50%] tw-z-10">
      <input
        id="switch-theme"
        type="checkbox"
        checked={isDark}
        className="tw-sr-only"
        onChange={() => {
          setIsDark(!isDark);
          toggleTheme("theme");
        }}
        aria-label="switch-theme"
      />
      <div
        className={`tw-py-[5px] tw-px-[5px] tw-relative tw-transition-all tw-rounded-tl-[20px] tw-rounded-bl-[20px] tw-h-10 [&:not(:hover)]:tw-w-8 [&:hover]:tw-w-16 ${isDark ? "tw-bg-_black-700 tw-shadow-[0_4px_5px_-2px_rgba(255,255,255,0.5),-3px_-1px_5px_-2px_rgba(255,255,255,0.5)]" : "tw-bg-_white tw-shadow-[0_4px_5px_-2px_rgba(0,0,0,0.5),-3px_-1px_5px_-2px_rgba(0,0,0,0.5)]"}`}
        onMouseOverCapture={() => {
          setActive(true);
        }}
        onMouseOutCapture={() => {
          setActive(false);
        }}
      >
        <div
          id="button-switch"
          className={`tw-h-full tw-relative tw-transition-all tw-duration-[600ms] tw-inline-flex tw-items-center tw-justify-center tw-aspect-square ${isDark ? "tw-bg-_white [&.active]:tw-translate-x-full [&.active]:tw-rounded-[10%] [&:not(.active)]:tw-rounded-bl-[50%] [&:not(.active)]:tw-rounded-tl-[50%] [&:not(.active)]:tw-rounded-r-[10%]" : "tw-bg-_black-700 tw-rounded-bl-[50%] tw-rounded-tl-[50%] tw-rounded-r-[10%]"} ${active ? "active" : ""}`}
        >
          <MyIcon
            hasBackground={false}
            iconName={isDark ? "ph:moon" : "ph:sun"}
            className={{
              icon: `${isDark ? "!tw-text-_black-700" : "tw-text-_white"}`,
            }}
          />
        </div>
      </div>
    </label>
  );
}
