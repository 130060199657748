import logoImage from "@/images/brands/logo.png";
import baseApiUrl from "@/utils/baseApiUrl";
import axios from "axios";
import { useRouter } from "next/compat/router";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useEffect, useState } from "react";
import gbFlag from "../../public/images/gb.svg";
import vnFlag from "../../public/images/vn.svg";
const Navbar = ({ pageProps }) => {
  const router = useRouter();
  const { locale } = router;
  // Logo API
  const [logo, setLogo] = React.useState();
  const { otherLangUrl = "" } = pageProps || {};
  const otherLocale = locale === "vi" ? "en" : "vi";
  React.useEffect(() => {
    const getLogo = async () => {
      const response = await axios.get(
        `${baseApiUrl}/api/site-logo?populate=*`
      );
      setLogo(response.data);
    };
    getLogo();
  }, []);
  // End Logo API
  const [navBar, setNavBar] = React.useState([
    {
      name: "Services",
      url: "#",
      dropdown: [
        { name: "Service 1", url: "/service-1" },
        { name: "Service 2", url: "/service-2" },
      ],
    },
  ]);
  React.useEffect(() => {
    const getNavBar = async () => {
      const response = await axios.get(
        `${baseApiUrl}/api/navbar?populate[navbar][populate]=*&locale=${locale}`
      );
      setNavBar(response.data.data.attributes.navbar);
    };
    getNavBar();
  }, [locale]);
  // Add active class
  const [currentPath, setCurrentPath] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    // hide sidebar on path change
    setShowSidebar(false);
  }, [pathname]);
  useEffect(() => {
    setCurrentPath(router.asPath);
  }, [router]);

  const [menu, setMenu] = React.useState(true);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const toggleNavbar = (event) => {
    event.preventDefault();
    setMenu(!menu);
  };
  const toggleDropdown = (index, e) => {
    if (window.innerWidth <= 768) {
      // Adjust this breakpoint as needed
      e.preventDefault();
      setActiveDropdown(activeDropdown === index ? null : index);
    }
  };

  useEffect(() => {
    const handleRouteChange = () => {
      setMenu(true);
      setActiveDropdown(null);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    // Cleanup the event listener on unmount
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  React.useEffect(() => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
  });
  // Multi Language
  const options = [
    {
      value: "Tiếng Việt - VI",
      code: "vi",
      label: vnFlag,
    },
    {
      value: "English - EN",
      code: "en",
      label: gbFlag,
    },
  ];
  const activeOption = options.filter((item) => {
    return item.code === router.locale;
  })[0];

  const classOne = menu
    ? "collapse navbar-collapse -tw-mx-[15px] mean-menu md:tw-bg-transparent tw-bg-_black-700"
    : "collapse navbar-collapse -tw-mx-[15px] show md:tw-bg-transparent tw-bg-_black-700";
  const classTwo = menu
    ? "navbar-toggler navbar-toggler-right tw-pr-2 collapsed"
    : "navbar-toggler navbar-toggler-right tw-pr-2";

  // Search Modal
  const [isActiveSearchModal, setActiveSearchModal] = useState("false");
  const handleToggleSearchModal = () => {
    setActiveSearchModal(!isActiveSearchModal);
  };

  return (
    <>
      {/* Start Header Area */}
      <header className="main-header-area homepage">
        {/* <Topbar /> */}
        <div id="navbar" className="navbar-area shadow shadow-black-800/70">
          <div className="main-navbar">
            <div className="container-fluid">
              <nav className="navbar navbar-expand-md">
                {logoImage && (
                  <div className="navbar-brand tw-relative tw-h-[fit-content]">
                    <Link href="/" className="navbar-brand tw-p-2">
                      <Image
                        quality={90}
                        overrideSrc="Thạch Anh ITT Logo"
                        alt="logo"
                        src={logoImage}
                        width={165}
                        height="auto"
                      />
                    </Link>
                  </div>
                )}

                <button
                  onClick={toggleNavbar}
                  className={classTwo}
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon-bar top-bar"></span>
                  <span className="icon-bar middle-bar"></span>
                  <span className="icon-bar bottom-bar"></span>
                </button>
                {/* NavBar */}
                <div className={classOne} id="navbarSupportedContent">
                  <ul className="navbar-nav ms-auto mt-0">
                    {navBar &&
                      navBar.map((item, index) => (
                        <li
                          key={index}
                          className="nav-item tw-relative tw-group tw-border-b tw-border-gray-900 md:tw-border-0"
                        >
                          <Link
                            onClick={(e) =>
                              item.subUrl &&
                              item.subUrl.length > 0 &&
                              toggleDropdown(index, e)
                            }
                            href={item.url}
                            className={`nav-link tw-uppercase tw-h-full hover:tw-bg-_red-600 tw-font-[700] lg:tw-text-navigation-lg ${
                              router.pathname === item.url && "active"
                            } tw-flex tw-items-center`}
                          >
                            {item.name}
                            {item.subUrl && item.subUrl.length > 0 && (
                              <svg
                                className={`tw-w-4 tw-h-4 tw-ml-1 tw-transition-transform ${
                                  activeDropdown === index
                                    ? "tw-rotate-180"
                                    : ""
                                }`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                            )}
                          </Link>
                          {item.subUrl && item.subUrl.length > 0 && (
                            <ul
                              className={`tw-absolute tw-left-0 tw-min-w-[200px] tw-max-w-[300px] tw-bg-_red-700 tw-rounded-b-md tw-shadow-lg tw-z-10 tw-transition-all tw-duration-300 tw-ease-in-out tw-transform 
                          md:tw-invisible md:tw-opacity-0 md:tw-translate-y-2 md:group-hover:tw-visible md:group-hover:tw-opacity-100 md:group-hover:tw-translate-y-0 tw-overflow-hidden
                          ${activeDropdown === index ? "tw-visible tw-opacity-100 tw-translate-y-0" : "tw-invisible tw-opacity-0 tw-translate-y-2"}`}
                            >
                              {item.subUrl.map((subItem, subIndex) => (
                                <li key={subIndex}>
                                  <Link
                                    target={subItem.target}
                                    href={subItem.url}
                                    className={`tw-block !tw-p-4 !tw-text-white tw-px-3 lg:!tw-px-[25px] tw-text-sm !tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis hover:!tw-text-white hover:tw-bg-_red-600
                                    ${subIndex === 0 ? "" : ""}
                                    ${subIndex === item.subUrl.length - 1 ? "tw-rounded-b-md" : ""}
                                  `}
                                    title={subItem.name} // Add this to show full text on hover
                                  >
                                    {subItem.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    <li className="tw-shrink tw-py-[25px] md:tw-ml-4">
                      <Link
                        href={
                          otherLangUrl && otherLocale in otherLangUrl
                            ? otherLangUrl[otherLocale]
                            : router.asPath
                        }
                        locale={otherLocale}
                        // href={router.asPath}
                        // locale={router.locale === "vi" ? "en" : "vi"}
                        scroll={false}
                      >
                        <Image
                          src={activeOption.label}
                          alt={activeOption.value}
                          width={25}
                          height="auto"
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* End Header Area */}

      {/* Search Form */}
      <div
        className={`search-overlay ${isActiveSearchModal ? "" : "search-overlay-active"}`}
      >
        <div className="d-table">
          <div className="d-table-cell">
            <div className="search-overlay-layer"></div>
            <div className="search-overlay-layer"></div>
            <div className="search-overlay-layer"></div>

            <div
              className="search-overlay-close"
              onClick={handleToggleSearchModal}
            >
              <span className="search-overlay-close-line"></span>
              <span className="search-overlay-close-line"></span>
            </div>

            <div className="search-overlay-form">
              <form>
                <input
                  type="text"
                  className="input-search"
                  placeholder="Search here..."
                />
                <button type="submit">
                  <i className="ri-search-2-line"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* End Search Form */}
    </>
  );
};

export default Navbar;
