import { Icon } from "@iconify/react";
import { Fragment } from "react";

export default function MyIcon({
   iconName,
   className,
   hasBackground,
   fontSize,
   iconColor,
}) {
   const WrappedTag = hasBackground ? "div" : Fragment;

   return (
      <WrappedTag
         {...(hasBackground
            ? {
                 className: `tw-relative tw-inline-flex tw-items-center tw-justify-center tw-bg-transparent tw-aspect-square tw-w-[48px] ${
                    className?.wrapper || ""
                 }`,
              }
            : {})}
      >
         {hasBackground && (
            <div
               className={`tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full ${className?.background || ""}`}
            ></div>
         )}
         <Icon
            icon={iconName}
            color={iconColor}
            fontSize={fontSize}
            className={`${className?.icon || ""} tw-relative`}
         />
      </WrappedTag>
   );
}

MyIcon.defaultProps = {
   iconName: "mdi:emoticon-penguin",
   className: {
      background: "tw-rounded-[50%] tw-bg-_white tw-opacity-10",
      icon: "",
      wrapper: "",
   },
   hasBackground: true,
   fontSize: 16,
   iconColor: "white",
};
