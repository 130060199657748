import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'aos/dist/aos.css';
import Layout from 'layout';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lightgallery.css';
import React from 'react';
import { register } from 'swiper/element/bundle';
import '../styles/animate.min.css';
import '../styles/bootstrap.min.css';
import '../styles/remixicon.css';
import '../styles/scss/custom.css';
import '../styles/scss/globals.css';
import '../styles/scss/navbar.css';
import '../styles/scss/responsive.css';
const queryClient = new QueryClient();
register();

function MyApp({ Component, pageProps }) {
  React.useEffect(() => {
    // defer from main thread
    setTimeout(() => {
      import('@/utils/theme').then(({ initializeTheme }) => {
        initializeTheme();
      });
      import('@/utils/worker/init').then(({ default: initWorker }) => {
        initWorker();
      });
    }, 0);
  }, []);

  const getLayout = Component.getLayout ?? ((page) => <Layout pageProps={pageProps}>{page}</Layout>);

  return getLayout(
    <QueryClientProvider client={queryClient}>
      <Component {...pageProps} />
    </QueryClientProvider>
  );
}

export default MyApp;
